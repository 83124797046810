.SocialLink {
    display: flex;
    position: relative;
    margin: var(--smallPadding) var(--smallPadding) var(--smallPadding) 0;
    font-size: 1em;
    line-height: 1.5em;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: rgb(var(--invertedTextColor));

    &.rss:hover {
        background: rgb(var(--rssColor));
    }

    &.whatsapp:hover {
        background: rgb(var(--whatsappColor));
    }

    &.facebook:hover {
        background: rgb(var(--facebookColor));
    }

    &.twitter:hover {
        background: rgba(var(--lighterTextColor), 0.5);
    }

    &.instagram:hover {
        background: rgb(var(--instagramColor));
    }

    &.youtube:hover {
        background: rgb(var(--youtubeColor));
    }

    &.tiktok:hover {
        background: rgb(var(--tiktokAquaColor));

        :global(.Logo svg path) {
            fill: rgb(var(--textColor));
        }
    }

    &.circular-link {
        border-radius: var(--circleRadius);
        overflow: hidden;
    }

    :global(.Logo) {
        display: block;
        height: var(--standard-icon-size);
        margin-right: var(--tinyPadding);
        padding: var(--tinyPadding);
        background: rgb(var(--thirdColor));
        border-radius: var(--standardRadius);
        aspect-ratio: 1;
        fill: white;

        svg {
            display: block;
        }
    }
}
