@use 'mixins';

$fontSizeHeading: 2.25em;
$fontSizeHeadingMobile: 1.75em;
$fontSizeSubHeading: 0.875em;
$fontSizeSubHeadingMobile: 0.75em;

.IntroBlock {
    padding-top: var(--standardPadding);

    h1 {
        display: inline-block;
        width: fit-content;
        padding: 0 0 var(--standardPadding) var(--standardPadding);
        margin: 0;
        font-style: normal;
        font-size: $fontSizeHeading;

        :global(.theme-vi) & {
            padding-left: 0;
            margin-left: var(--standardPadding);
            line-height: 1em;
            font-size: 1.75em;
            color: rgb(var(--textColor));
            border-bottom: var(--thickerBorderWidth) solid rgb(var(--mainColor));

            sub {
                margin-left: var(--smallPadding);
                color: inherit;
                font-weight: inherit;
            }
        }
    }

    h3 {
        margin: 0;
        padding: 0 0 0 var(--standardPadding);
        font-size: $fontSizeSubHeading;

        :global(.theme-vi) & {
            display: none;
        }
    }

    hr {
        display: none;

        :global(.theme-vi) & {
            display: block;
        }
    }

    .description {
        margin: 0;
        padding: 0 var(--standardPadding) var(--largePadding) var(--standardPadding);
        line-height: 1.5em;

        :global(.theme-vi) & {
            margin-top: var(--standardPadding);
            font-weight: 600;
            font-family: var(--text-font-family);
            color: rgb(var(--lighterTextColor));
        }
    }

    &.inverted {
        padding: var(--standardPadding);
        background-color: rgb(var(--invertedBackground));
        border-bottom: 4px solid rgb(var(--thirdColor));
        border-radius: var(--standardRadius);
        color: rgb(var(--lighterTextColor));

        h1 {
            font-size: $fontSizeHeading;
            color: rgb(var(--invertedTextColor));
        }
    }

    .main-drop-holder {
        display: inline;
        float: right;
    }

    &.underline {
        h1 {
            :global(.theme-vp) & {
                border-bottom: 3px solid rgb(var(--thirdColor));
            }

            border-bottom: 3px solid rgb(var(--secondColor));
        }

        hr {
            display: block;
            opacity: 1;
            background-color: rgb(var(--sectionBorder));
        }
    }

    :global(.theme-gp) & {
        hr {
            display: block;
            opacity: 1;
            background-color: rgb(var(--sectionBorder));
        }

        h1 {
            border-bottom: 3px solid rgb(var(--mainColor));
        }

        &.videos h1 {
            border-bottom-color: rgb(var(--fourthColor));
        }
    }

    :global(.theme-vi) & {
        border-bottom: 1px solid rgb(var(--lightSectionBack));
    }

    @include mixins.responsive(m, below) {
        h1 {
            padding: 0 0 var(--smallPadding) var(--standardPadding);
            font-size: $fontSizeHeadingMobile;

            :global(.theme-vi) & {
                font-size: 1.5em;
            }
        }

        h3 {
            font-size: $fontSizeSubHeadingMobile;
        }
    }
}
